var htmlPage = $('html'),
    pageOverlay = $('#overlay-fixed');


( function( $, window, document, undefined )
{
    'use strict';

    var elSelector		= '',
        $element		= $( elSelector );

    var elHeight		= 0,
        elTop			= 0,
        $document		= $( document ),
        dHeight			= 0,
        $window			= $( window ),
        wHeight			= 0,
        wScrollCurrent	= 0,
        wScrollBefore	= 0,
        wScrollDiff		= 0;

    $window.on( 'scroll', function()
    {
        elHeight		= $element.outerHeight();
        dHeight			= $document.height();
        wHeight			= $window.height();
        wScrollCurrent	= $window.scrollTop();
        wScrollDiff		= wScrollBefore - wScrollCurrent;
        elTop			= parseInt( $element.css( 'top' ) ) + wScrollDiff;

        if( wScrollCurrent <= 0 )
        { 
            htmlPage.removeClass("page-scrolling scroll-up scroll-down");
        }
        else if( wScrollDiff > 0 )
        {
            htmlPage.addClass("page-scrolling");
            htmlPage.addClass("scroll-up").removeClass("scroll-down");
        }
        else if( wScrollDiff < 0 )
        {
            htmlPage.addClass("page-scrolling");
            htmlPage.removeClass("scroll-up").addClass("scroll-down");
        }
        
        
        wScrollBefore = wScrollCurrent;
    });

})( jQuery, window, document );


/* Detect OS */
$(function(){
    
    var isDevice = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
            return (isDevice.Android() || isDevice.BlackBerry() || isDevice.iOS() || isDevice.Opera() || isDevice.Windows());
        }
    };
    
    if( isDevice.any() ) {
        htmlPage.addClass('is-device');
    } else {
        htmlPage.removeClass('is-device');
    }
    
});


/* IE or Edge Browser Version */
$(function(){
    var version = detectIE();

    if (version === false) {
        htmlPage.removeClass("ie-edge");
        htmlPage.removeClass("edge");
        htmlPage.removeClass("ie");
    } else if (version >= 12) {
        htmlPage.addClass("ie-edge");
        htmlPage.addClass("edge");
    } else {
        htmlPage.addClass("ie-edge");
        htmlPage.addClass("ie");
    }

    function detectIE() {
        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }
});


/* Window Resize */
function viewportHeight() {
    if( !($("html").hasClass("ie")) ) {
        
        var vh = window.innerHeight * (0.01);
        document.documentElement.style.setProperty('--vh', vh + 'px');
        
    }
}

function pageStyle() {
    if( $('#header-fixed').length ){
        $('#page').css('padding-top', $('#header-fixed').outerHeight());
    }
}

function bgChange(Obj) {
    let bgImg = $(Obj);
    let bgImgSrc = $(Obj).data('bgimg-src');
    let bgImgSrcset = typeof $(Obj).data('bgimg-srcset') != 'undefined' ? $(Obj).data('bgimg-srcset') : '';

    var width = $(document).width();
    if (width < 768 && bgImgSrcset != '' ) {
        bgImg.css({ "background-image": 'url(' + bgImgSrcset + ')' });
    } else {
        bgImg.css({ "background-image": 'url(' + bgImgSrc + ')' });
    }
}

function bgChangeInit() {
    $('.bg-img').each(function(){
        var Obj = $(this);
        bgChange( Obj ); 
    });
}

function imgChange(Obj) {
    let img = $(Obj);
    let imgSrc = $(Obj).data('img-src');
    let imgSrcset = typeof $(Obj).data('img-srcset') != 'undefined' ? $(Obj).data('img-srcset') : '';

    var width = $(document).width();
    if (width < 768 && imgSrcset != '' ) {
        img.attr("src", imgSrcset);
    } else {
        img.attr("src", imgSrc);
    }
}

function imgChangeInit() {
    $('.img-change').each(function(){
        var Obj = $(this);
        imgChange( Obj ); 
    });
}

$(function(){
    viewportHeight();
    
    pageStyle();
    bgChangeInit();
    imgChangeInit();
    
    $(window).resize(function(){
        viewportHeight();
        
        pageStyle();
        bgChangeInit();
        imgChangeInit();
    });
});


/* Animate */
$(function(){    
    if($(".animsition").length){
        $(".animsition").animsition({
            inClass: 'fade-in',
            outClass: 'fade-out',
            inDuration: 1200,
            outDuration: 600,
            linkElement: 'a[href]:not([target="_blank"]):not([href^="#"]):not([href^="tel"]):not([href^="mailto"]):not([href^="javascript"]):not([href=""]):not([class*="open-popup-"])',
            loading: false
        });
    }
    
    if($(".animate").length){
        var wow = new WOW({ 
            boxClass: 'animate'
        });
        wow.init();
    }
});


/* Dot */
$(function(){
    var txt = $(".dot"),
        txtDestroy = $(".destroy");
    if(txt.length){
        txt.dotdotdot({
            ellipsis: '...',
            wrap: 'word',
            fallbackToLetter: true,
            watch: true,
            //after: '.ic'
        });
    }
    if(txtDestroy.length){
        txtDestroy.trigger("destroy");
    }
});


/* Match Height */
$(function(){
    if($(".matchheight-group").length){
        $('.matchheight-group').each(function() {
            $(this).find('.matchheight').matchHeight({
                byRow: false
            });
            
            $(this).find('.matchheight-byrow').matchHeight({
                byRow: true
            });
            
            $(this).find('.matchheight-element').matchHeight({
                byRow: true
            });
        });
    }
});


/* Form */
$(function(){
    
    var formElement = $('input, textarea, select');
    
    formElement.each(function(){
        if( !$(this).val() ) {	
            $(this).closest('.input').removeClass('filled');
        } else {
            $(this).closest('.input').addClass('filled');
        }
    });
    
    formElement.focusin(function(){
        $(this).closest('.input').addClass('filled');
    });
    
    formElement.focusout(function(){
        if( !$(this).val() ) {	
            $(this).closest('.input').removeClass('filled');
        }
    });
    
    // Select
    $(".select").each(function() {
        var selectParent = $(this),
            select = $(this).find(".select2"),
            selectFilter = $(this).find(".select2-filter");
        
        var query = {};
        function markMatch (text, term) {
            var match = text.toUpperCase().indexOf(term.toUpperCase());
            
            var $result = $('<span></span>');

            if (match < 0) {
                return $result.text(text);
            }

            $result.text(text.substring(0, match));

            var $match = $('<span class="select2-rendered__match"></span>');
            $match.text(text.substring(match, match + term.length));

            $result.append($match);

            $result.append(text.substring(match + term.length));

            return $result;
        }
        
        select.select2({
            width: '100%',
            minimumResultsForSearch: -1,
            dropdownParent: selectParent,
            templateResult: function (item) {
                if (item.loading) {
                    return item.text;
                }

                var term = query.term || '';
                var $result = markMatch(item.text, term);

                return $result;
            },
            language: {
                searching: function (params) {
                    query = params;
                    return 'Searching...';
                }
            }
        });
        
        selectFilter.select2({
            width: '100%',
            allowClear: true,
            dropdownParent: selectParent,
            templateResult: function (item) {
                if (item.loading) {
                    return item.text;
                }

                var term = query.term || '';
                var $result = markMatch(item.text, term);

                return $result;
            },
            language: {
                searching: function (params) {
                    query = params;
                    return 'Searching...';
                }
            }
        }).on("select2:unselecting", function(e) {
            $(this).data('state', 'unselected');
        }).on("select2:open", function(e) {
            if ($(this).data('state') === 'unselected') {
                $(this).removeData('state'); 
                var self = $(this);
                self.select2('close');
            } 
        });
        
        select.parent(".select").addClass("select2-parent");
        selectFilter.parent(".select").addClass("select2-parent");
        
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            select.select2("destroy");
            select.parent(".select").removeClass("select2-parent");
        }
    });
    
    // Datepicker : file include jquery-ui-datepicker.min.css, jquery-ui-datepicker.min.js
    !$("html").hasClass("is-device") && $(".px-datepicker-device").attr("type","text").addClass("px-datepicker").removeClass("px-datepicker-device");
    
    if($(".px-datepicker-device").length) {
        $(".px-datepicker-device").parents('.datepicker').addClass('datepicker-device');
    }
    if($(".px-datepicker").length) {
        var inputDatepicker = $(".px-datepicker");
        inputDatepicker.datepicker({ 
            dateFormat: "dd/mm/yy",
            //changeMonth: true,
            //changeYear: true,
            showOn: "both",
            buttonText: "",
            onSelect: function(selected,evnt) {
                console.log("test");
                $(this).parents('.input').addClass('filled');
            }
        });

        $(window).resize(function() {
          inputDatepicker.datepicker('hide');
          inputDatepicker.blur();
        });
    }
    
    // Choice
    $('.choice-other').each(function(){
        var label = $(this).find('.choice-label input[type="checkbox"], .choice-label input[type="radio"]'),
            input = $(this).find('.input > input');

        label.click(function(){
            if( $(this).prop("checked") == true ) {
                input.attr( "disabled", false ).focus();
                input.parent(".input").removeClass("disabled");
            } else {
                input.attr( "disabled", true );
                input.parent(".input").addClass("disabled");
            }
        });
    });
});


/* Upload File */
$(function(){
    // Browser supports HTML5 multiple file?
    var multipleSupport = typeof $('<input/>')[0].multiple !== 'undefined',
        isIE = /msie/i.test( navigator.userAgent );

    $.fn.customFile = function() {

        return this.each(function() {

            var $file = $(this).addClass('custom-file-upload-hidden'), // the original file input
                $wrap = $('<div class="file-upload-wrapper">'),
                $input = $('<input type="text" class="file-upload-input" placeholder="เลือกไฟล์ยืนยันตัวตน..." readonly />'),
                // Button that will be used in non-IE browsers
                $button = $('<div class="file-upload-action"><button type="button" class="btn file-upload-button">เลือกไฟล์</button></div>'),
                // Hack for IE
                $label = $('<div class="file-upload-action"><label class="btn file-upload-button" for="'+ $file[0].id +'">เลือกไฟล์</label></div>');

            // Hide by shifting to the left so we
            // can still trigger events
            $file.css({
                position: 'absolute',
                left: '-9999px'
            });

            $wrap.insertAfter( $file )
                .append( $file, $input, ( isIE ? $label : $button ) );

            // Prevent focus
            $file.attr('tabIndex', -1);
            $button.attr('tabIndex', -1);

            // Open dialog
            $button.click(function () {
                $file.focus().click(); 
            });

            $input.click(function () {
                $file.focus().click();
            });

            $file.change(function() {

                var files = [], fileArr, filename;

                // If multiple is supported then extract
                // all filenames from the file array
                if ( multipleSupport ) {
                    fileArr = $file[0].files;
                    for ( var i = 0, len = fileArr.length; i < len; i++ ) {
                        files.push( fileArr[i].name );
                    }
                    filename = files.join(', ');

                // If not supported then just take the value
                // and remove the path to just show the filename
                } else {
                    filename = $file.val().split('\\').pop();
                }

                $input.val( filename ) // Set the value
                    .attr('title', filename) // Show filename in title tootlip
                    .focus(); // Regain focus

                $input.closest('.input').addClass('filled');
            });

            $input.on({
                blur: function() { $file.trigger('blur'); },
                keydown: function( e ) {
                    if ( e.which === 13 ) { // Enter
                        if ( !isIE ) { $file.trigger('click'); }
                    } else if ( e.which === 8 || e.which === 46 ) { // Backspace & Del
                        // On some browsers the value is read-only
                        // with this trick we remove the old input and add
                        // a clean clone with all the original events attached
                        $file.replaceWith( $file = $file.clone( true ) );
                        $file.trigger('change');
                        $input.val('');
                    } else if ( e.which === 9 ) { // TAB
                        return;
                    } else { // All other keys
                        return false;
                    }
                }
            });

        });

    };

    // Old browser fallback
    if ( !multipleSupport ) {
        $( document ).on('change', 'input.customfile', function() {

      var $this = $(this),
          // Create a unique ID so we
          // can attach the label to the input
          uniqId = 'customfile_'+ (new Date()).getTime(),
          $wrap = $this.parent(),

          // Filter empty input
          $inputs = $wrap.siblings().find('.file-upload-input')
            .filter(function(){ return !this.value }),

          $file = $('<input type="file" id="'+ uniqId +'" name="'+ $this.attr('name') +'"/>');

      // 1ms timeout so it runs after all other events
      // that modify the value have triggered
      setTimeout(function() {
        // Add a new input
        if ( $this.val() ) {
          // Check for empty fields to prevent
          // creating new inputs when changing files
          if ( !$inputs.length ) {
            $wrap.after( $file );
            $file.customFile();
          }
        // Remove and reorganize inputs
        } else {
          $inputs.parent().remove();
          // Move the input so it's always last on the list
          $wrap.appendTo( $wrap.parent() );
          $wrap.find('input').focus();
        }
      }, 1);

    });
    }

    $('input[type=file]').customFile();
});


/* Popup */
$(function(){
    // Popup Modal
    $('.open-popup-modal').magnificPopup({
        type: 'inline',
        preloader: false,
        closeOnBgClick: false,
        mainClass: 'popup-style popup-style-modal',
        showCloseBtn: false,
        removalDelay: 400
    });
    
    // Popup Content
    $('.open-popup-content').magnificPopup({
        type: 'inline',
        midClick: true,
        alignTop: true,
        mainClass: 'popup-style popup-style-content',
        closeOnBgClick: false,
        removalDelay: 400
    });
    if( location.hash != '' && location.hash.indexOf('popup') > 0 ){
        $.magnificPopup.open({
            items:{
                src: location.hash
            },
            type: 'inline',
            midClick: true,
            alignTop: true,
            mainClass: 'popup-style popup-style-content',
            closeOnBgClick: false,
            removalDelay: 400
        });   
    }
    
    // Close Popup
    $(document).on('click', '.modal-dismiss', function(e) {
		e.preventDefault();
		$.magnificPopup.close();
	});
});


/* Tab */
function showTab( elem ){
    $(elem).parents('.tab-group').find('.tab-content').removeClass('active');
    $(elem + '.tab-content').addClass('active');
}
function selectDestroyMobile(Obj){
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        Obj.select2("destroy");
        Obj.parent(".select").removeClass("select2-parent");
    }
}
$(function(){
    var tabGroupParent = null;
    var Selec2Options = null;

    $('.tab-group').each(function(){
        var obj = $(this);
        Selec2Options = {
            width: '100%',
            minimumResultsForSearch: -1,
            dropdownParent: obj.find('select.tab-select2').parents('.select')
        };

        var mySelect2 = obj.find('select.tab-select2');
        mySelect2.select2( Selec2Options );
        mySelect2.parents(".select").addClass("select2-parent");
        selectDestroyMobile(mySelect2);
    });

    $('.tab-group .control a').click(function( e ){
        var tabGroupParent = $(this).parents('.tab-group');
        var _id = $(this).attr('href');
        Selec2Options.dropdownParent = tabGroupParent.find('select.tab-select2').parents('.select')

        tabGroupParent.find('.control a').removeClass('active');
        tabGroupParent.find('select option').prop('selected', false).removeAttr('selected');
        $(this).addClass('active');
        showTab( _id );
        tabGroupParent.find('select option[value="'+ _id +'"]').prop('selected', true).attr('selected', true);
        tabGroupParent.find('select.tab-select2').select2('destroy');
        tabGroupParent.find('select.tab-select2').select2( Selec2Options );

        selectDestroyMobile( tabGroupParent.find('select.tab-select2') );

        e.preventDefault();
    });

    $('.tab-group select.tab-select2').change(function(){
        $(this).parents('.tab-group')
        $(this).parents('.tab-group').find('.control a').removeClass('active');
        $('.tab-group .control a[href="'+ $(this).val() +'"]').addClass('active')
        showTab( $(this).val() );
    });
});


/* Accordion */
$(function(){
    $('.accordion-group').each(function(){
        var acc = $(this).find('.accordion');
        
        acc.each(function(){
            var heading = $(this).find('.heading'),
                content = $(this).find('.content'),
                neighbor = $(this).siblings(),
                neighborContent = neighbor.find('.content');
            
            heading.click(function(){
                $(this).parent().toggleClass('active');
                content.slideToggle(400);
                //neighbor.removeClass('active');
                //neighborContent.slideUp(400);
            });
            
        });
    });
});


/* Location Hash */
$(function(){
    setTimeout(function(){
        var nav = $('#header-fixed').outerHeight(),
            h = 70;
        
        if (window.location.hash) {
            $('html, body').delay(100).animate({
                scrollTop: $(window.location.hash).offset().top-h
            }, 1000, 'easeOutQuad');
        }
    }, 100);
});


/* Link Scroll */
$(function(){
    $('.link-scroll').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var h = 70;
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top-h
                }, 1000, 'easeOutQuad');
                return false;
            }
        }
    });
});


/* Nav */
$(function(){
    var sections = $('.main > .section'), 
        nav = $('.nav'),
        h = 70;
    
    $(window).on('scroll', function () {
        var cur_pos = $(this).scrollTop();

        sections.each(function() {
            var top = $(this).offset().top - h - 2,
                bottom = top + $(this).outerHeight();

            if (cur_pos >= top && cur_pos <= bottom) {
                nav.find('a').removeClass('active');
                sections.removeClass('active');

                $(this).addClass('active');
                nav.find('a[href="#'+$(this).attr('id')+'"]').addClass('active');
            }
        });
    });
    
    nav.find('a').on('click', function () {
        var $this = $(this),
            id = $this.attr('href');

        $('html, body').animate({
        scrollTop: $(id).offset().top - h}, 1000, 'easeOutQuad');

        return false;
    });
});


/* Panel */
$(function(){    
    $(".panel-ctrl").click(function(){
        var dataPanel = $(this).data('panel-ctrl');
        htmlPage.addClass("panel-enabled");
        $('*[data-panel='+ dataPanel +']').addClass("active");
    });
    
    $(".panel-close").click(function(){
        htmlPage.removeClass("panel-enabled");
        $(this).parents(".panel").removeClass("active");
    });
});


/* fixTo */
$(function(){
    var functionFixed = $("#function-fixed");
    if(functionFixed.length) {        
        functionFixed.fixTo(".art-detail", { 
            mind: '#header-fixed',
            useNativeSticky: false 
        });
        functionFixed.siblings(".fixto-float").addClass("function-fixed-float");
    }
});


/* Counter */
$(function(){
    if($('.counter').length){
        $('.counter').countUp({
            time: 1000
        });
    }
});


/* Slider */
// Slider - Default
$(function(){
    if($('.swiper-container.default').length){
        $(".swiper-container.default").each(function(){
            var sliderWrapper = $(this).find(".swiper-wrapper"),
                sliderPagination = $(this).find(".swiper-pagination"),
                sliderButtonPrev = $(this).find(".swiper-button-prev"),
                sliderButtonNext = $(this).find(".swiper-button-next"),
                sliderScrollbar = $(this).find(".swiper-scrollbar"),
                total = $(this).find(".swiper-slide").length;
            
            // Option
            var sliderFade = $(this).hasClass("fade"),
                sliderLoop = $(this).hasClass("loop") && (total > 1),
                sliderAutoplay = $(this).hasClass("autoplay") && (total > 1),
                sliderFraction = $(this).hasClass("fraction");
            

            var swiper = new Swiper($(this), {
                pagination: {
                    el: ( (sliderPagination.length) ? sliderPagination : '' ),
                    type: ( (sliderFraction) ? 'fraction' : 'bullets' ),
                    clickable: true,
                },
                navigation: {
                    prevEl: ( (sliderButtonPrev.length) ? sliderButtonPrev : '' ),
                    nextEl: ( (sliderButtonNext.length) ? sliderButtonNext : '' ),
                },
                scrollbar: {
                    el: ( (sliderScrollbar.length) ? sliderScrollbar : '' ),
                    clickable: true,
                    draggable: true,
                    snapOnRelease: true,
                },
                effect: ( (sliderFade) ? 'fade' : 'slide' ),
                loop: sliderLoop,
                speed: 750,
                longSwipesMs: 750,
                autoplay: {
                    delay: 8000,
                },
            });
            
            if( !sliderAutoplay ) {
                swiper.autoplay.stop();
            } else {
                swiper.autoplay.start();
            }

            if(total < 2) {
                swiper.destroy();

                sliderPagination.hide();
                sliderButtonPrev.hide();
                sliderButtonNext.hide();
                sliderScrollbar.hide();
            }
            
        });
    }
});

// Slider - Centered
$(function(){
    if($('.swiper-container.centered').length){
        $(".swiper-container.centered").each(function(){
            var sliderWrapper = $(this).find(".swiper-wrapper"),
                sliderPagination = $(this).find(".swiper-pagination"),
                sliderButtonPrev = $(this).find(".swiper-button-prev"),
                sliderButtonNext = $(this).find(".swiper-button-next"),
                sliderScrollbar = $(this).find(".swiper-scrollbar"),
                total = $(this).find(".swiper-slide").length;
            
            // Option
            var sliderFade = $(this).hasClass("fade"),
                sliderLoop = $(this).hasClass("loop") && (total > 1),
                sliderAutoplay = $(this).hasClass("autoplay") && (total > 1),
                sliderFraction = $(this).hasClass("fraction");
            

            var swiper = new Swiper($(this), {
                pagination: {
                    el: ( (sliderPagination.length) ? sliderPagination : '' ),
                    type: ( (sliderFraction) ? 'fraction' : 'bullets' ),
                    clickable: true,
                },
                navigation: {
                    prevEl: ( (sliderButtonPrev.length) ? sliderButtonPrev : '' ),
                    nextEl: ( (sliderButtonNext.length) ? sliderButtonNext : '' ),
                },
                scrollbar: {
                    el: ( (sliderScrollbar.length) ? sliderScrollbar : '' ),
                    clickable: true,
                    draggable: true,
                    snapOnRelease: true,
                },
                effect: ( (sliderFade) ? 'fade' : 'slide' ),
                loop: sliderLoop,
                speed: 800,
                longSwipesMs: 800,
                autoplay: {
                    delay: 8000,
                },
                slidesPerView: 'auto',
                centeredSlides: true,
                spaceBetween: 0,
            });
            
            if( !sliderAutoplay ) {
                swiper.autoplay.stop();
            } else {
                swiper.autoplay.start();
            }

            if(total < 2) {
                swiper.destroy();

                sliderPagination.hide();
                sliderButtonPrev.hide();
                sliderButtonNext.hide();
                sliderScrollbar.hide();
            }
            
        });
    }
});

// Slider - Card
$(function(){
    var ele = $('.card-group.slider .swiper-container, .calendar-card-group.slider .swiper-container');

    if(ele.length){
        ele.each(function(){
            var sliderWrapper = $(this).find(".swiper-wrapper"),
                sliderPagination = $(this).find(".swiper-pagination"),
                sliderButtonPrev = $(this).find(".swiper-button-prev"),
                sliderButtonNext = $(this).find(".swiper-button-next"),
                sliderScrollbar = $(this).find(".swiper-scrollbar"),
                total = $(this).find(".swiper-slide").length;
            
            // Option
            var sliderFade = $(this).hasClass("fade"),
                sliderLoop = $(this).hasClass("loop") && (total > 1),
                sliderAutoplay = $(this).hasClass("autoplay") && (total > 1),
                sliderFraction = $(this).hasClass("fraction");
            
            var swiper = new Swiper($(this), {
                pagination: {
                    el: ( (sliderPagination.length) ? sliderPagination : '' ),
                    type: ( (sliderFraction) ? 'fraction' : 'bullets' ),
                    clickable: true,
                },
                navigation: {
                    prevEl: ( (sliderButtonPrev.length) ? sliderButtonPrev : '' ),
                    nextEl: ( (sliderButtonNext.length) ? sliderButtonNext : '' ),
                },
                scrollbar: {
                    el: ( (sliderScrollbar.length) ? sliderScrollbar : '' ),
                    clickable: true,
                    draggable: true,
                    snapOnRelease: true,
                },
                effect: ( (sliderFade) ? 'fade' : 'slide' ),
                loop: sliderLoop,
                slidesPerView: 'auto',
                spaceBetween: 0,
                speed: 600,
                init: false
            });
            
            swiper.init();
            
            if( !sliderAutoplay ) {
                swiper.autoplay.stop();
            } else {
                swiper.autoplay.start();
            }
            
        });
    }
});


/* Clipboard */
$(function(){
    var clipboard = new ClipboardJS('.copytoclipboard', {
        text: function(trigger) {
            return trigger.getAttribute('aria-label');
        }
    });

    clipboard.on('success', function() {
        $('#clipboard').fadeIn(300);
        setTimeout(function() {
            $('#clipboard').fadeOut(300);
        }, 1000);
    });
});

/* Social Share */
function addQS(d, c) {
    var a = [];
    for (var b in c)
    if (c[b]) a.push(b.toString() + '=' + encodeURIComponent(c[b]));
    return d + '?' + a.join('&')
}

function getMETAContent(attr, data) {
    var meta = document.getElementsByTagName("META"),
        max = meta.length;
    for (var i = 0; i < max; i++) {
        if (meta[i].getAttribute(attr) == data) {
            return meta[i].content;
        }
    }
    return -1;
}

function fbShare() {
    var g = {
        u: document.URL,
        title: document.title
    };
    var a = addQS('https://www.facebook.com/sharer.php', g);
    window.open(a, 'sharer', 'toolbar=0,status=0,width=626,height=436');
    return false
}

function fbIMGShare(img) {
    var g = {
        u: document.URL + "?fbimg=" + img,
        title: document.title
    };
    var a = addQS('https://www.facebook.com/sharer.php', g);
    window.open(a, 'sharer', 'toolbar=0,status=0,width=626,height=436');
    return false
}

function fbURLShare(u,title) {
    var g = {
        u: u,
        title: (title != '' ? title : document.title)
    };
    var a = addQS('https://www.facebook.com/sharer.php', g);
    window.open(a, 'sharer', 'toolbar=0,status=0,width=626,height=436');
    return false;
}

function tweetShare() {
    var g = {
        url: document.URL,
        text: document.title + ' - '
    };
    var a = addQS('http://twitter.com/share', g);
    window.open(a, 'tweet', 'toolbar=0,status=0,width=626,height=436');
    return false;
}

function tweetURLShare(u) {
    var g = {
        url: u,
        text: document.title + ' - '
    };
    var a = addQS('http://twitter.com/share', g);
    window.open(a, 'tweet', 'toolbar=0,status=0,width=626,height=436');
    return false;
}

function lineMSG() {
    window.location = "line://msg/text/" + encodeURIComponent(document.URL);
}

function lineURLMSG(u) {
    window.location = "line://msg/text/" + encodeURIComponent(u);
}

function linkinShare() {
    var a = 'https://www.linkedin.com/shareArticle?mini=true&url=' + document.URL + '&title=' + document.title;
    window.open(a, 'sharer', 'toolbar=0,status=0,width=626,height=436');
}

function linkinURLShare(u) {
    var a = 'https://www.linkedin.com/shareArticle?mini=true&url=' + u;
    window.open(a, 'sharer', 'toolbar=0,status=0,width=626,height=436');
}
